<template>
  <div class="appointDetail">
    <div class="box">
      <div class="areaAppointItem">
        <div class="appointList-t">
          <div class="id">工单号：{{ detailData.id }}</div>
          <div class="state">{{ getStatus(detailData) }}</div>
        </div>
        <div class="appointList-c">
          <div class="name">{{ detailData.placeName }}</div>
          <div class="address">{{ detailData.address }}</div>
          <div class="status">
            {{ detailData.scheduleList[0].strTimeStatus }}
          </div>
        </div>
        <div class="appointList-b">
          <div class="timeBox">
            <div class="title">下单时间</div>
            <div class="time">{{ detailData.referTime }}</div>
          </div>
          <div class="timeBox">
            <div class="title">预约场次</div>
            <div class="time">
              <div
                class="scheduleList"
                v-for="(oItem, oIndex) in detailData.scheduleList"
                :key="oIndex"
              >
                {{ oItem.strDateTime }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="code"
      v-for="(item, index) in detailData.scheduleList"
      :key="index"
    >
      <div class="code-content" v-if="item.qrcode || item.qrcodeErrorMsg">
        <div class="qr-code">
          <div class="item-t">
            <div class="dot-line"></div>
            <div class="dot-line"></div>
          </div>
          <div class="item-b">
            <div class="item-title">开门二维码{{ index + 1 }}</div>
            <div class="item-code">
              <div
                class="item-code-left"
                v-if="item.qrcode"
                @click="handelClickCode(item)"
              >
                <vue-qr :text="item.qrcode"></vue-qr>
              </div>
              <div v-else class="item-code-left actived">
                <img src="./img/code-error.png" alt="" />
              </div>
              <div class="item-code-right">
                <div class="common">
                  <div>预约场次</div>
                  <div>{{ item.strDateTime }}</div>
                </div>
                <div class="common" v-if="item.qrcode">
                  <div>关联设备</div>
                  <div>{{ item.hotspotNames }}</div>
                </div>
                <div class="common" v-else>
                  <div>提示消息</div>
                  <div>{{ item.qrcodeErrorMsg }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="opera" v-if="detailData.cancelBook">
      <div class="btn" @click="cancel">取消订单</div>
    </div>
    <div class="emptyBox"></div>
    <v-dialog v-model="isDialog" title="提示" @confirm="confirm">
      <p style="text-align: center; line-height: 40px">是否确认取消订单？</p>
    </v-dialog>
    <!-- 弹窗二维码 -->
    <div class="dialog" v-if="isShowCode">
      <div class="dialog-content">
        <div class="code">
          <vue-qr :text="dialogData.qrcode"></vue-qr>
        </div>
        <div class="close" @click="isShowCode = false">
          <img src="./img/close.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { myAppointDetailUrl, cancelAppointUrl } from "./api.js";
import { appointStatus } from "./map.js";
import { getOption } from "@/utils/utils.js";
var moment = require("moment");
import vueQr from "vue-qr";
export default {
  name: "appointDetail",
  components: {
    vueQr,
  },
  data() {
    return {
      timer: null,
      orderId: "",
      detailData: {},
      isDialog: false,
      isShowCode: false, // 控制二维码弹窗
      dialogData: {
        //二维码弹窗数据
        qrcode: "",
      },
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
  },
  created() {
    this.orderId = this.$route.query.orderId;
  },
  mounted() {
    this.getMyAppointDetail();
  },
  methods: {
    //点击二维码显示弹窗
    handelClickCode(item) {
      this.dialogData.qrcode = item.qrcode;
      this.isShowCode = true;
    },
    cancel() {
      this.isDialog = true;
    },
    confirm() {
      let params = {
        orderId: this.orderId,
      };
      this.$axios
        .post(`${cancelAppointUrl}?orderId=${this.orderId}`)
        .then((res) => {
          if (res.code === 200) {
            this.$toast({
              message: "取消成功",
              duration: 300,
            });
            this.timer = setTimeout(() => {
              this.$router.replace({
                name: "appointList",
              });
            }, 400);
          } else {
          }
        });
    },
    getStatus(item) {
      if (item.status) {
        console.log(
          88,
          item,
          getOption(item.status, appointStatus, "key").value
        );
        return getOption(item.status, appointStatus, "key").value;
      } else {
        return "";
      }
    },

    getMyAppointDetail() {
      let params = {
        orderId: this.orderId,
      };
      this.$axios
        .get(`${myAppointDetailUrl}`, {
          params,
        })
        .then((res) => {
          if (res.code === 200) {
            this.detailData = res.data;
          } else {
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.appointDetail {
  box-sizing: border-box;
  min-height: 100vh;
  background: #f4f5ff;
  padding: 28px 28px 230px 28px;
  // padding-bottom: 200px;
  // padding-top: 28px;
  .dialog {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
    .dialog-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      box-sizing: border-box;
      .code {
        width: 400px;
        height: 400px;
        border-radius: 16px;
        overflow: hidden;
        border: 10px solid #96bffb;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .close {
        width: 62px;
        height: 62px;
        margin-top: 84px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .box {
    width: 100%;
    min-height: 402px;
    box-sizing: border-box;
    // padding: 0 28px;
    .areaAppointItem {
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.49);
      border-radius: 16px;
      height: 100%;
    }

    .appointList-t {
      box-sizing: border-box;
      height: 72px;
      width: 100%;
      position: relative;
      color: rgba(0, 0, 0, 0.25);
      font-size: 24px;

      .id {
        position: absolute;
        left: 38px;
        top: 50%;
        transform: translateY(-50%);
      }

      .state {
        position: absolute;
        right: 32px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .appointList-c {
      box-sizing: border-box;
      width: 100%;
      height: 150px;
      border-top: 2px solid rgba(0, 0, 0, 0.05);
      border-bottom: 2px solid rgba(0, 0, 0, 0.05);
      position: relative;

      .name {
        font-size: 36px;
        font-weight: 600;
        color: #333333;
        position: absolute;
        left: 40px;
        top: 34px;
      }

      .address {
        font-size: 26px;
        color: rgba(0, 0, 0, 0.5);
        position: absolute;
        left: 40px;
        top: 82px;
      }

      .status {
        font-size: 30px;
        font-weight: 600;
        color: #5e5aff;
        position: absolute;
        top: 36px;
        right: 32px;
      }
    }

    .appointList-b {
      width: 100%;
      padding: 42px 32px 24px 40px;
      box-sizing: border-box;

      .timeBox {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .title {
          width: 200px;
          font-size: 32px;
          color: rgba(0, 0, 0, 0.5);
        }

        .time {
          flex: 1;
          font-size: 30px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
          text-align: right;
        }
      }
    }
  }
  .code {
    box-sizing: border-box;
    margin-top: 20px;
    .code-content {
      width: 100%;
      box-sizing: border-box;
      background: #ffffff;
      border-radius: 16px;
      padding: 40px;
      box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.49);
      .qr-code {
        display: flex;
        flex-direction: column;
        height: 100%;
        .item-t {
          width: 100%;
          position: relative;
          left: 0;
          top: -85px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .dot-line {
            position: relative;
            width: 6px;
            height: 68px;
            background: #9ac9e1;
            border-radius: 4px;
            &::before,
            &::after {
              content: "";
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              width: 18px;
              height: 18px;
              background: rgba(222, 241, 251);
              border-radius: 50%;
            }
            &::before {
              top: -9px;
            }
            &::after {
              bottom: -9px;
            }
          }
        }
        .item-b {
          margin-top: -68px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          .item-title {
            font-size: 28px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
            line-height: 40px;
          }
          .item-code {
            display: flex;
            align-items: center;
            margin-top: 28px;
            .item-code-left {
              margin-right: 28px;
              width: 190px;
              height: 190px;
              border: 6px solid #8db6fa;
              border-radius: 6px;
              &.actived {
                border: none;
                border-radius: 0;
              }
              img {
                width: 100%;
                height: 100%;
              }
            }
            .item-code-right {
              flex: 1;
              display: flex;
              flex-direction: column;
              .common {
                &:first-child {
                  margin-bottom: 12px;
                }
                div {
                  &:first-child {
                    height: 36px;
                    font-size: 26px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.5);
                    line-height: 36px;
                    margin-bottom: 5px;
                  }
                  &:last-child {
                    line-height: 40px;
                    // text-overflow: ellipsis;
                    // display: -webkit-box;
                    // -webkit-line-clamp: 3;
                    // -webkit-box-orient: vertical;
                    // overflow: hidden;
                    font-size: 28px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: rgba(0, 0, 0, 0.85);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .opera {
    width: 100%;
    padding: 0 100px;
    left: 0;
    bottom: 134px;
    position: fixed;
    height: 66px;
    box-sizing: border-box;

    .btn {
      box-sizing: border-box;
      height: 66px;
      border-radius: 10px;
      border: 2px solid;
      font-size: 30px;
      font-weight: 600;
      color: #ffff;
      background: linear-gradient(180deg, #9591ff 0%, #5f5bff 100%);
      text-align: center;
      line-height: 66px;
    }
  }
  .emptyBox {
    width: 100%;
    height: 76px;
    // background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
  }
}
</style>
